<template>
  <div>
    <v-btn class="mr-2 mb-2" text color="success" small @click="dialog = true"
      ><v-icon>mdi-list-box</v-icon>Invoice</v-btn
    >
    <v-dialog v-model="dialog" persistent width:500>
      <v-card>
        <v-sheet color="success"
          ><h1 class="pa-2 ml-4 white--text">Invoice</h1></v-sheet
        >
        <v-card-text>
          <div v-for="invoice in invoices" :key="invoice.id" class="mt-3">
            <h3 class="success--text mb-5">
              <span
                :class="
                  invoice.is_quote == 1
                    ? 'mt-n1 success--text mb-n'
                    : 'mt-n1 warning--text mb-n'
                "
              >
                {{ invoice.is_quote == 1 ? "Quote" : "Invoice" }} :
              </span>
              {{ invoice.invoice_code }}
            </h3>
            <div>
              <v-row>
                <v-col>
                  <p class="mt-n2">
                    <v-icon small class="mr-1">mdi-cash</v-icon>Amount:
                    {{ invoice.amount }}
                  </p>
                  <p class="mt-n3">
                    <v-icon small class="mr-1">mdi-hand-coin</v-icon>Balance:
                    {{ invoice.balance }}
                  </p>
                  <p class="mt-n3">
                    <v-icon small class="mr-1">mdi-percent</v-icon>Discount:
                    {{ invoice.discount }}
                  </p>
                </v-col>
                <v-col>
                  <p class="mt-n3">
                    <v-icon small class="mr-1">mdi-calendar</v-icon> Invoice
                    Date: {{ invoice.invoice_date }}
                  </p>
                  <p
                    :class="
                      invoice.invoice_status == 'Paid'
                        ? 'mt-n3 success--text'
                        : invoice.invoice_status == 'Waiting for Payment'
                        ? 'mt-n3 error--text'
                        : 'mt-n3 warning--text'
                    "
                  >
                    {{ invoice.invoice_status }}
                  </p>
                </v-col>
              </v-row>
              <v-divider class="mt-1 mb-1"></v-divider>
              <h3 class="mb-3">Payables</h3>
              <div v-if="invoice.payables.length > 0">
                <div>
                  <ol>
                    <li v-for="payable in invoice.payables" :key="payable.id">
                      <!-- if service or item -->
                      <span v-if="payable.payable.service_name">
                        {{ payable.payable.service_name }}</span
                      >
                      <span v-else>
                        {{ payable.payable.item_name }}
                      </span>
                    </li>
                  </ol>
                </div>
              </div>
              <v-divider class="mt-1 mb-1"></v-divider>
              <!-- PAYMENTS -->
              <h3 class="mb-3">Payments</h3>
              <div v-if="invoice.payments.length > 0">
                <div
                  v-for="payment in invoice.payments"
                  :key="payment.id"
                  class=""
                >
                  <v-row>
                    <v-col>
                      <p>Payment ID: {{ payment.id }}</p>
                      <p class="mt-n3">Amount: {{ payment.amount_paid }}</p>
                    </v-col>
                    <v-col>
                      <p class="">Method: {{ payment.payment_method }}</p>
                      <p class="mt-n3">Date: {{ payment.payment_date }}</p>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-else>
                <h4 class="">No payments yet.</h4>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["invoices"],
  data() {
    return {
      dialog: false,
    };
  },
  created() {
    console.log(this.invoices);
  },
};
</script>

<style lang="scss" scoped></style>
